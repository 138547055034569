import React, { useEffect, useState } from 'react'
import useMobile from '../../../shared/hooks/useMobile'
import homeLink from '../../../shared/utils/siteUrl'
import ProductDescription from '../../../components/molecules/product-description/product-description'
import BreadCrumb from '../../../shared/components/atoms/breadcrumb/breadcrumb'
import ProductsRelated from '../../../components/molecules/product-related/product-related'
import ProductRating from '../../../components/molecules/product-rating/product-rating'
import SectionCommentarys from '../../../shared/components/molecules/section-commentary/section-commentary'
import SeoProductInfo from '../../../components/molecules/seo-product-info/seo-product-info'
import ArticlesRelated from '../../../components/molecules/article-related/article-related'
import ProducService from '../../../shared/services/product'
import LogoPetys from '../../../shared/assets/images/logo-petys.svg'
import './product-detail-content.scss'

const ProductDetailContent = (props) => {

  const { params, setModalActive } = props
  const { product: productInfo, parentSlug } = params
  const [contadorDeOpiniones, setContadorDeOpiniones] = useState(0)
  const [itemsRate, setItemsRate] = useState(null)
  const [itemsRateDetails, setItemsRateDetails] = useState(null)
  const [ratingDetail, setRatingDetail] = useState([{}])
  const isMobile = useMobile();

  /*Compare items cms whith back and assign value*/
  const setListItemsRate = itemsRateBack => {
    let newList = []
    productInfo.preguntasCalificacion && productInfo.preguntasCalificacion.forEach((element) => {
      let resultado = itemsRateBack.find((item) => item.item.toLowerCase() === element.nombreCategoria.toLowerCase())
      newList.push(resultado != undefined ? resultado : { rate: 0, item: element.nombreCategoria })
    })
    setItemsRate(newList)
  }

  /*sort and fill missing items in details rate*/
  const setItemsDetailsRate = itemsDetailBack => {
    let listCompare = [1, 2, 3, 4, 5]
    let newList = []
    listCompare.forEach((element) => {
      let resultado = itemsDetailBack.find((i) => i.id == element)
      newList.push(resultado != undefined ? resultado : { id: element, vote: 0 })
    })
    setItemsRateDetails(newList.reverse())
  }

  useEffect(() => {
    const payload = {
      cmsId: productInfo.idDelProducto,
    }

    ProducService.getRate(payload).then(res => {
      if (res.status === 200) {
        setContadorDeOpiniones(res.data.totalVotesDetail)
        setListItemsRate(res.data.items)
        setItemsDetailsRate(res.data.detail)
        setRatingDetail(res.data)
      } else {

      }
    }).catch(e => console.log("error servicio productos", e))

  }, [])

  return (
    <div className="f-product-detail">
      <div className="f-breadcrum-position"><BreadCrumb mobile={isMobile} /></div>
      <div className="f-product-title-mobile-container">
        <div className="f-product-title-mobile">
          <a href={homeLink + "productos/"} className="f-product-icon"><i className="fas fa-arrow-left"></i></a>
          <h3 className="f-section-title">PRODUCTOS</h3>
          <a href={homeLink} className="f-product-img"><img src={LogoPetys} alt="Logo petys" /></a>
        </div>
      </div>
      <ProductDescription
        contadorDeOpiniones={contadorDeOpiniones}
        ratingDetail={ratingDetail}
        setModalActive={setModalActive}
        productInfo={productInfo}
        isMobile={isMobile}
        parentSlug={parentSlug}
      />
      {itemsRateDetails &&
        itemsRate &&
        <>
          <ProductRating
            ratingValue={productInfo.ratingValue}
            contadorDeOpiniones={contadorDeOpiniones}
            itemsRateDetails={itemsRateDetails}
            itemsRate={itemsRate}
            ratingDetail={ratingDetail}
          />
          <SectionCommentarys
            productInfo={productInfo}
            setModalActive={setModalActive}
            isMobile={isMobile}
          />
        </>
      }
      {productInfo.referenciasProductosDestacados &&
        <ProductsRelated
          parentSlug={parentSlug}
          referenciasProductosDestacados={productInfo.referenciasProductosDestacados}
        />}
      {productInfo.referenciasArticulosRelacionados &&
        <ArticlesRelated
          referenciasArticulosRelacionados={productInfo.referenciasArticulosRelacionados}
        />}
      {productInfo.informacionAdicional &&
        <SeoProductInfo seoInfo={productInfo.informacionAdicional}/>
      }
    </div>
  )
}

export default ProductDetailContent
