import React from "react"
import RichText from "../../../shared/components/atoms/rich-text/rich-text"
import "./seo-product-info.scss"

const SeoProductInfo = props => {
  const { seoInfo } = props

  return (
    <div className="f-seo-section">
      {seoInfo?.map((item, index) => {
        const {
          tipoDeInformacionAdicional: tipo,
          informacionAdicional1,
          imagenInformacionAdicional1,
        } = item

        if (tipo === "Titulo + Descripcion") {
          return (
            <RichText
              key={index}
              className="f-seo-section__general f-seo-section__tipo1"
              jsonInfo={informacionAdicional1?.json}
            />
          )
        } else if (tipo === "Imagen Izquierda + Descripcion + Fondo Gris") {
          return (
            <div key={index} className="f-seo-section--fondo-gris">
              <div className="f-seo-section__general f-seo-section__general--reverse f-seo-section__tipo2">
                <div className="f-column">
                  <figure className="f-seo-section__tipo2__img">
                    {imagenInformacionAdicional1 ? (
                      <img
                        src={imagenInformacionAdicional1[0]?.file.url}
                        alt={imagenInformacionAdicional1[0]?.title}
                      />
                    ) : null}
                  </figure>
                </div>
                <RichText
                  className="f-column "
                  jsonInfo={informacionAdicional1?.json}
                />
              </div>
            </div>
          )
        } else if (tipo === "Descripciones Divididas") {
          return (
            <div
              key={index}
              className={
                "f-seo-section__general f-seo-section__tipo3 " +
                (!item["imagenInformacionAdicional3"] &&
                !item["imagenInformacionAdicional2"]
                  ? "f-seo-section__tipo3--two"
                  : "")
              }
            >
              {[1, 2, 3].map((element, index) => {
                if (
                  item["imagenInformacionAdicional" + element] ||
                  item["informacionAdicional" + element]
                ) {
                  return (
                    <div key={index} className="f-seo-section__tipo3__card ">
                      {item["imagenInformacionAdicional" + element] &&
                        item["imagenInformacionAdicional" + element][0] && (
                          <figure className="f-seo-section__tipo3__card__img">
                            <img
                              src={
                                item["imagenInformacionAdicional" + element][0]
                                  ?.file?.url
                              }
                              alt={
                                item["imagenInformacionAdicional" + element][0]
                                  ?.title
                              }
                            />
                          </figure>
                        )}
                      {item["informacionAdicional" + element] && (
                        <RichText
                          className=""
                          jsonInfo={
                            item["informacionAdicional" + element]?.json
                          }
                        />
                      )}
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>
          )
        } else if (tipo === "Imagen Derecha + Descripcion") {
          return (
            <div className="f-seo-section__general f-seo-section__tipo4" key={index}>
              <RichText
                className="f-column "
                jsonInfo={informacionAdicional1?.json}
              />

              <div className="f-column">
                <figure className="f-seo-section__tipo4__img">
                  {imagenInformacionAdicional1 ? (
                    <img
                      src={imagenInformacionAdicional1[0]?.file.url}
                      alt={imagenInformacionAdicional1[0]?.title}
                    />
                  ) : null}
                </figure>
              </div>
            </div>
          )
        } else if (tipo === "Descripcion Fondo Gris") {
          return (
            <div className="f-seo-section--fondo-gris" key={index}>
              <RichText
                className="f-seo-section__general f-seo-section__tipo5 "
                jsonInfo={informacionAdicional1?.json}
              />
            </div>
          )
        } else if (tipo === "Imagen Izquierda + Descripcion") {
          return (
            <div className="f-seo-section__general f-seo-section__general--reverse f-seo-section__tipo6" key={index}>
              <div className="f-column">
                <figure className="f-seo-section__tipo4__img">
                  {imagenInformacionAdicional1 ? (
                    <img
                      src={imagenInformacionAdicional1[0]?.file.url}
                      alt={imagenInformacionAdicional1[0]?.title}
                    />
                  ) : null}
                </figure>
              </div>

              <RichText
                className="f-column "
                jsonInfo={informacionAdicional1?.json}
              />
            </div>
          )
        } else {
          return null
        }
      })}
    </div>
  )
}

export default SeoProductInfo
