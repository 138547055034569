import React, { useState } from 'react'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import Button from '../../../shared/components/atoms/button/button'
import ModalRetailer from "../../../shared/components/modals/modal-retailer/modal-retailer"
import StarRatings from 'react-star-ratings'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './product-description.scss'

const SectionProducts = (props) => {

  const { ratingDetail, productInfo, setModalActive, isMobile, parentSlug  } = props
  const { presentacionesDelProducto, carritosDeCompra, descripcionDelProducto, nombreDelProducto, slug } = productInfo
  const { totalVotesDetail: contadorDeOpiniones } = ratingDetail
  const [isAllDescription, setisAllDescription] = useState(false)


  /*Rich text options*/
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <span className="ftext-bold">{text}</span>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="f-text">{children}
        {children[0].length >= (isMobile ? 162 : 198) && <span className="f-show-more-text" onClick={showAllDescription}>cargar más...</span>}
      </p>,
    },
    renderText: text => {
      return text.slice(0, (isMobile ? 162 : 198)).concat(" ")
    },
  }

  const optionDescription = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="f-text">{children}</p>,
    },
  }

  /*Banner presentations configuration*/
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: false,
  };

  const listItems = presentacionesDelProducto && presentacionesDelProducto.map((presentation, key) => {
    return (
      <div className="f-slider-img-container" key={key}>
        <figure className="f-slider-img">
          <img src={presentation.imagenPresentacionDelProducto.file.url} alt={presentation.imagenPresentacionDelProducto.description} />
          <span className="f-unids-tag">{presentation.presentacionDelProducto}</span>
        </figure>
      </div>
    )
  })

  /*Modal retailer*/
  const onClose = () => {
    setModalActive(null)
  }

  const showAllDescription = () => {
    setisAllDescription(true)
  }

  const showRetailerModal = (e) => {
    e.preventDefault()
    setModalActive(modalRetailer)
  }

  let listslugs = {
    'eliminador-de-olores-lavanda-relajante-petys/' : 'https://www.cuidateenfamilia.com/item-eliminador_de_olores_petys_lavanda_x_280_ml-356671',
    'panitos-humedos-lavanda-relajante-petys/' : 'https://www.cuidateenfamilia.com/item-panos_humedos_petys_lavanda_x_50_und-356670',
  }

  const modalRetailer = <ModalRetailer onClose={onClose} retailerInfo={carritosDeCompra} />

  return (
    <div className="f-product-description">
      <div className="f-product-presentation">
        <Slider {...settings} className="f-slider-presentation">
          {listItems}
        </Slider>
      </div>
      <div className="f-product-content">
        <h1 className="f-product-title">{nombreDelProducto}</h1>
        <div className="f-product-stars-opinion">
          <StarRatings
            rating={ratingDetail && ratingDetail.rate !== null ? ratingDetail.rate : 0}
            starRatedColor="#ffc645"
            starEmptyColor="#E6E6E6"
            numberOfStars={5}
            starDimension="24px"
            starSpacing="5px"
            name='rating'
          />
          <span className="f-product-opinion">({contadorDeOpiniones ? contadorDeOpiniones : 0})</span>
        </div>
        {
          isAllDescription ?
            documentToReactComponents(descripcionDelProducto && descripcionDelProducto.json, optionDescription)
            :
            documentToReactComponents(descripcionDelProducto && descripcionDelProducto.json, options)
        }
        <div className="f-interaction-container">
          <Button
            className={"f-primary-outline-purple f-rating-button"}
            title="CALIFICAR &#9733;"
            href={parentSlug + productInfo.slug + "calificar/"}
            onClick={() => {
              window.location.href = "/" + parentSlug + productInfo.slug + "calificar/"
            }} />
          {carritosDeCompra && carritosDeCompra.length > 0 && <Button className={"f-primary-purple f-shop-button"} typeButton="button" title="COMPRAR" href=''
            onClick={(e) => {
              if (listslugs[slug]) {
                window.open(listslugs[slug], '_blank')
              } else {
                showRetailerModal(e)
              }
            }} />}
        </div>
      </div>
    </div>
  )
}

export default SectionProducts;