import React from 'react'

const ProgressBar = (props) => {
  const { bgcolor = "#78BE20", className, completed = 0, absolute = 100 } = props;

  const calculateCompleted = () => {
    return (completed / absolute) * 100
  }

  const containerStyles = {
    height: 20,
    width: '100%',
    backgroundColor: "#F6F7FB",
    borderRadius: 50,
    overflow: 'hidden',
  }

  const fillerStyles = {
    height: '100%',
    width: `${calculateCompleted()}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 1s ease-in-out',
    display: 'flex',
    alignItems: 'center',
  }

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold'
  }

  return (
    <div style={containerStyles} className={className ? className : ''}>
      <div style={fillerStyles}>
        <span style={labelStyles}></span>
      </div>
    </div>
  );
};

export default ProgressBar;