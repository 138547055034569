import React from 'react'
import ProductDetailContent from '../../components/organisms/product-detail-content/product-detail-content'
import SEO from "../../shared/components/atoms/seo/seo"
import Layout from '../../shared/components/organisms/layout/layout'
import useMobie from '../../shared/hooks/useMobile'
import siteUrl from '../../shared/utils/siteUrl'
import { unicodeUtil } from '../../shared/utils/unicode-util'

const ProductTemplate = props => {
  const { pageContext } = props;

  const [modalActive, setModalActive] = React.useState(null);
  const isMobile = useMobie();

  let seoImg;
  let schema;

  const productData = props.pageContext.product;
  if (productData) {
    try {
      seoImg = productData?.imagenDelProducto?.file?.url;
    } catch (e) {
      console.error('error al obtener la imagen', e);
    }

    let ratingValue = productData.ratingValue !== null ? productData.ratingValue : 5;
    let reviewCount = productData.reviewCount !== null ? productData.reviewCount : 1;

    if (productData.schemas) {
      const newSchemas = productData.schemas.schemas;
      let stringSchema = unicodeUtil(newSchemas.replace(/(\r\n|\n|\r)/gm, ""));
      let parseSchema = JSON.parse(stringSchema);
      schema = parseSchema;
      if(schema.aggregateRating?.ratingValue){
      schema.aggregateRating.ratingValue = ratingValue.toString();
      schema.aggregateRating.reviewCount = reviewCount.toString();
      }
    }

  }

  return (
    <Layout activeMenu={1} modalActive={modalActive} logoMobile={false} logoSearch={false} shopButton={!isMobile} isProductDetail={isMobile}>
      <SEO
        title={pageContext.product.metaTitulo ? pageContext.product.metaTitulo : ''}
        description={pageContext.product.metaDescripcion ? pageContext.product.metaDescripcion?.metaDescripcion : ''}
        slug={siteUrl + (pageContext.product.slug ? pageContext.parentSlug + pageContext.product.slug : '')}
        image={seoImg}
        script={schema}
      />
      <ProductDetailContent params={pageContext} setModalActive={setModalActive} />
    </Layout>
  )
}

export default ProductTemplate;