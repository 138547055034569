import React from 'react'
import ProgressBar from '../../../shared/components/atoms/progress-bar/progress-bar'
import StarRatings from 'react-star-ratings'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './product-rating.scss'

const ProductsRelated = (props) => {

  const { ratingDetail, itemsRate, contadorDeOpiniones = 0, itemsRateDetails } = props

  const formatDecimal = number => number.toFixed(1);
  const calculatePercentage = number => number / 5;

  return (
    <div className="f-product-rating">

      <div className="f-rating">
        <h3 className="f-rating-title">Calificación</h3>
        <span className="f-rating-value">{ratingDetail && ratingDetail.rate != null ? formatDecimal(ratingDetail?.rate) : 0}/5</span>
        <div className="f-rating-stars">
          <StarRatings
            rating={ratingDetail && ratingDetail.rate != null ? ratingDetail?.rate : 0}
            starRatedColor="#ffc645"
            starEmptyColor="#E6E6E6"
            numberOfStars={5}
            starDimension="24px"
            starSpacing="5px"
            name='rating'
          />
        </div>
        <span className="f-rating-opinions">({contadorDeOpiniones} opiniones)</span>
      </div>

      <div className="f-rating-details">
        <h3 className="f-rating-details-title">Detalle del promedio</h3>
        <div className="f-rating-details-votes">
          {itemsRateDetails !== null ?
            itemsRateDetails.map((item, key) => {
              return (
                <div className="f-rating-vote" key={key}>
                  <span className="f-votes-number">{item.id}</span>
                  <ProgressBar className="f-rating-votes-progress" completed={item.vote} absolute={contadorDeOpiniones !== 0 ? contadorDeOpiniones : 100} />
                  <span className="f-votes-number-details">{item.vote + " votos / " + contadorDeOpiniones}</span>
                </div>
              )
            })
            :
            <div className="f-rating-vote">
              <p>No se han registrado votos</p>
            </div>
          }
        </div>
      </div>

      <div className="f-rating-items">
        <h3 className="f-rating-items-title">Items calificados</h3>
        <div className="f-rating-items-rate">
          <div className="f-rating-items-list">
            {itemsRate ?
              itemsRate.map((item, key) => {
                return (
                  <div className="f-rating-item" key={key}>
                    <p className="f-rating-item-name">{item.item}</p>
                    <div className="f-rating-item-value-start">
                      <span className="f-rating-item-value">{formatDecimal(item.rate)}</span>
                      <StarRatings
                        rating={calculatePercentage(item.rate)}
                        starRatedColor="#ffc645"
                        starEmptyColor="#E6E6E6"
                        numberOfStars={1}
                        starDimension="24px"
                        starSpacing="5px"
                      />
                    </div>
                  </div>
                )
              })
              :
              <div className="f-rating-vote">
                <p className="f-no-results">No se han registrado items</p>
              </div>
            }
          </div>
          <div className="f-rating-mobile">
            <span className="f-rating-value">{ratingDetail && ratingDetail.rate != null ? formatDecimal(ratingDetail?.rate) : 0}/5</span>
            <span className="f-rating-opinions">({contadorDeOpiniones} opiniones)</span>
          </div>
        </div>
      </div>
    </div >
  )

}

export default ProductsRelated
