import React from 'react'
import CardArticle from '../../../shared/components/atoms/card-article/card-article'
import Button from '../../../shared/components/atoms/button/button'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './article-related.scss'

const ArticlesRelated = (props) => {

  const { referenciasArticulosRelacionados } = props

  return (
    <div className="f-related-article">
      <div className="f-center-content">
        <div className="f-related-article-header">
          <h2 className="f-related-article-title">Articulos Relacionados</h2> <Button className={"f-link-green f-show-all"} typeButton="link" title="Ver todos" href="blog-de-expertos/" />
        </div>
        <div className="f-related-grid">
          {referenciasArticulosRelacionados && referenciasArticulosRelacionados.slice(0, 4).map((article, key) => {
            return (
              <CardArticle className="f-card-related" type={2} article={article} parentSlug={"blog-de-expertos/"} key={key} />
            )
          })}
        </div>
      </div>
    </div>
  )

}

export default ArticlesRelated
